const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'Home' */ '../views/Auth/AuthLayout.vue'),
    meta: {
      membersOnly: false
    },
    children: [
      {
        path: '/signup',
        name: 'Signup',
        component: () => import(/* webpackChunkName: 'Signup' */ '../views/Auth/Signup.vue'),
        meta: {
          title: 'Signup'
        }
      },
      {
        path: '',
        alias: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: 'Login' */ '../views/Auth/Login.vue'),
        meta: {
          title: 'Login'
        }
      },
      {
        path: '/reset',
        name: 'Reset',
        component: () => import(/* webpackChunkName: 'Login Help' */ '../views/Auth/Reset.vue'),
        meta: {
          title: 'Password Help'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'Dashboard' */ '../views/Dashboard/Dashboard.vue'),
    meta: {
      membersOnly: true
    },
    children: [
      {
        path: '/movies',
        name: 'Movies',
        component: () => import(/* webpackChunkName: "Films" */ '../views/Dashboard/Films/Movies/Movies.vue'),
        meta: {
          title: 'Movies'
        }
      },
      {
        path: '/movie',
        name: 'Movie',
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Dashboard/Films/Movies/Movie.vue')
      },
      {
        path: '/calendar',
        name: 'Calendar',
        component: () => import(/* webpackChunkName: "Films" */ '../views/Dashboard/Films/Movies/Calendar.vue'),
        meta: {
          title: 'Calendar'
        }
      },
      {
        path: '/collections',
        name: 'Collections',
        component: () => import(/* webpackChunkName: "Films" */ '../views/Dashboard/Films/Collections.vue'),
        meta: {
          title: 'Collections'
        }
      },
      {
        path: '/collection',
        name: 'Collection',
        component: () => import(/* webpackChunkName: "Show" */ '../views/Dashboard/Films/Collection.vue')
      },
      {
        path: '/shows',
        name: 'Shows',
        component: () => import(/* webpackChunkName: "Films" */ '../views/Dashboard/Films/Shows/Shows.vue'),
        meta: {
          title: 'Shows'
        }
      },
      {
        path: '/show',
        name: 'Show',
        component: () => import(/* webpackChunkName: "Show" */ '../views/Dashboard/Films/Shows/Show.vue')
      },
      {
        path: '/stars',
        name: 'Stars',
        meta: {
          title: 'Stars'
        },
        component: () => import(/* webpackChunkName: "Stars" */ '../views/Dashboard/People/People.vue')
      },
      {
        path: '/star',
        name: 'Star',
        component: () => import(/* webpackChunkName: "Star" */ '../views/Dashboard/People/Person.vue')
      },
      {
        path: '/profile',
        name: 'Profile',
        meta: {
          title: 'Profile'
        },
        component: () => import(/* webpackChunkName: "Profile" */ '../views/Dashboard/Profile/Profile.vue')
      },
      {
        path: '/watch',
        name: 'Watch',
        component: () => import(/* webpackChunkName: "Watch" */ '../views/Dashboard/Films/Watch.vue')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'Not Found',
    meta: {
      title: '404'
    },
    component: () => import(/* webpackChunkName: "404" */ '../views/404/NotFound.vue')
  }
]

export default routes
