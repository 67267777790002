import router from '@/router'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, getDocs, query, collection, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getAnalytics, logEvent } from "firebase/analytics";
import { db } from '@/services/firebase'

const state = () => ({
  user: {},
  watchlist: {
    movies: [],
    shows: []
  },
  favorites: {
    movies: [],
    shows: [],
    stars: []
  },
  alert: {
    type: '',
    code: '',
    message: ''
  },
  collections: [],
  watched: {
    movies: [],
    shows: []
  }
})

const getters = {
  getMovieWatchlist: (state) => state.user.movieWatchlist ? state.user.movieWatchlist : [],
  getShowWatchlist: (state) => state.user.showWatchlist ? state.user.showWatchlist : [],
  getFavoriteMovies: (state) => state.user.favoriteMovies ? state.user.favoriteMovies : [],
  getFavoriteShows: (state) => state.user.favoriteShows ? state.user.favoriteShows : [],
  getFavoriteStars: (state) => state.user.favoriteStars ? state.user.favoriteStars : []
}

const mutations = {
  SET_USER: (state, val) => {
    state.user = val
  },

  SET_COLLECTIONS: (state, val) => {
    state.collections.push(val)
  },

  SET_WATCHED_ITEMS: (state, { type, val }) => {
    state.watched[type] = val
  },

  UPDATE_WATCHED_ITEMS: (state, { type, val }) => {
    // Locate iteam in array
    let index = state.watched[type].indexOf(val)
    // If item is new, push to array
    if (index < 0) state.watched[type].push(val)
    // IF item exists, remove from array
    else state.watched[type].splice(index, 1)
  },

  SET_ALERT: (state, val) => {
    if (val) {
      state.alert.type = val.type
      state.alert.message = val.message
      if (val.code) state.alert.code = val.code
    } else {
      state.alert.type = ''
      state.alert.message = ''
    }
  },

  SET_WATCHLIST: (state, { category, val }) => {
    state.watchlist[category].push(val)
  },

  SET_FAVORITES: (state, { category, val }) => {
    state.favorites[category].push(val)
  },

  UPDATE_WATCHLIST: (state, { category, type, val }) => {
    // Locate item index
    let index1 = state.user[category].indexOf(val.id)
    let index2 = state.watchlist[type].findIndex(x => x.id === val.id)

    if (index1 < 0 && index2 < 0) {
      state.user[category].push(val.id)
      state.watchlist[type].push(val)
    } else {
      state.user[category].splice(index1, 1)
      state.watchlist[type].splice(index2, 1)
    }
  },

  UPDATE_FAVORITES: (state, { category, type, val }) => {
    // Locate item index
    let index1 = state.user[category].indexOf(val.id)
    let index2 = state.favorites[type].findIndex(x => x.id === val.id)

    if (index1 < 0 && index2 < 0) {
      state.user[category].push(val.id)
      state.favorites[type].push(val)
    } else {
      state.user[category].splice(index1, 1)
      state.favorites[type].splice(index2, 1)
    }
  }
}

const actions = {

  signUp: async function (context, { username, email, password }) {
    try {
      // Sign up new user
      const response = await createUserWithEmailAndPassword(getAuth(), email, password)
      // Retrieve user data
      const user = response.user
      // Create user doc in firebase
      await context.dispatch('createUser', { user, username })
      // Send user verification email
      await sendEmailVerification(user)
      // Alert user to verify email
      context.commit('SET_ALERT', { type: 'success', message: 'Success! Please verify your email to continue.'})
      // Track analytics
      await logEvent(getAnalytics(), 'sign_up', { username })
      // Sign out user
      signOut(getAuth())
    }
    catch(error) {
      context.dispatch('utilities/logError', {
        created: new Date().getTime(),
        error: error.message,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})
    }
  },

  signIn: async function (context, { email, password, redirect = 'movies' }) {
    try {
      // Sign in user
      const response = await signInWithEmailAndPassword(getAuth(), email, password)
      // Retrieve user data
      const user = response.user
      // If email is verified, redirect to desired path
      user.emailVerified ? router.push(redirect)
      // If email is not verified, display alert
      : context.commit('SET_ALERT', { type: 'error', code: 'verify-email', message: 'Oops! Looks like you forgot to verify your email.'})
    }
    catch(error) {
      switch (error.code) {
        case 'auth/user-not-found':
          context.commit('SET_ALERT', { type: 'error', message: `Account for ${email} was not found.`})
          break;
        case 'auth/wrong-password':
          context.commit('SET_ALERT', { type: 'error', code: 'wrong-password', message: 'The password you entered is incorrect.'})
          break;
        default: console.log(`An error occured while signing in. Error: ${error.code}`)
      }

      context.dispatch('utilities/logError', {
        created: new Date().getTime(),
        error: error.message,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})
    }
  },

  signOut: async () => {
    // Sign out user
    await signOut(getAuth())
    // Redirect user to login page
    // router.go()
    router.replace('login') // doesn't interfere with previousPath functionality
  },

  resetPassword: (context, email) => {
    // Send password reset email
    sendPasswordResetEmail(getAuth(), email)
    // Display success alert
    .then(() => context.commit('SET_ALERT', { type: 'success', message: `We sent a password reset email to ${email}.`}))
    .catch(error => {
      if (error.code === 'auth/user-not-found') context.commit('SET_ALERT', { type: 'error', message: `Account for ${email} was not found.`})
      else context.commit('SET_ALERT', { type: 'error', message: `Error: ${error} Please try again.`})
    })
  },

  createUser: async (context, { user, username }) => {
    try {
      await setDoc(doc(getFirestore(), 'Users', user.uid), {
        created: new Date().getTime(),
        email: user.email,
        favoriteMovies: [],
        favoriteShows: [],
        favoriteStars: [],
        id: user.uid,
        movieWatchlist: [],
        showWatchlist: [],
        username: username,
      })
    } catch(error) {
      context.dispatch('utilities/logError', {
        created: new Date().getTime(),
        error: error.message,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})
    }
  },

  getUser: async (context, id) => {
    try {
      // Locate and retrieve user details from database
      let user = await getDoc(doc(db, 'Users', id))
      // Store user data in state
      context.commit('SET_USER', user.data())
      // Retrieve watched movies
      let watchedMovies = await getDoc(doc(db, 'Users', id, 'Watched', 'Movies'))
      // Store watched movies in state
      if (watchedMovies.data()) context.commit('SET_WATCHED_ITEMS', { type: 'movies', val: watchedMovies.data().items })
      // Retrieve watched shows
      let watchedShows = await getDoc(doc(db, 'Users', id, 'Watched', 'Shows'))
      // Store watched movies in state
      if (watchedShows.data()) context.commit('SET_WATCHED_ITEMS', { type: 'shows', val: watchedShows.data().items })
      // Retrieve watched shows
      // let watchedShows = await getDoc(doc(db, 'Users', id, 'Watched', 'Shows'))
      // Store watched shows in state
      // if (watchedShows.data()) context.commit('SET_WATCHED_ITEMS', { type: 'shows', val: watchedShows.data().items })
      // If user email was verified, update user doc
      if(getAuth().currentUser.emailVerified && !user.data().emailVerified) {
        updateDoc(doc(getFirestore(), 'Users', user.data().id), { emailVerified: true })
      }
    }
    // Catch and log error to console
    catch(error) {
      context.dispatch('utilities/logError', {
        created: new Date().getTime(),
        error: error.message,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})
    }
  },

  updateUser: async (context, details) => {
    try {
      // Update user doc in database
      await updateDoc(doc(db, 'Users', context.state.user.id), details)
    }
    catch(error) {
      context.dispatch('utilities/logError', {
        created: new Date().getTime(),
        error: error.message,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})
    }
  },

  getCollections: async (context) => {
    try {
      let collections = await getDocs(query(collection(db, 'Collections')))

      collections.forEach(collection => context.commit('SET_COLLECTIONS', collection.data()))
    }
    catch(error) {
      context.dispatch('utilities/logError', {
        created: new Date().getTime(),
        error: error.message,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})
    }
  },

  updateWatchedLists: async (context, { operation, type, item }) => {
    if (operation === 'add') {
      // NOTE: Using set instead of update because the user will need to
      // create the document first and then update it afterwards
      await setDoc(doc(db, 'Users', context.state.user.id, 'Watched', type), {
        items: arrayUnion(item.id)
      }, { merge: true })

      // Log activity in database
      context.dispatch('utilities/logActivity', {
        created: new Date().getTime(),
        activity: 'Added watched item',
        item: item.name || item.title,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})

      logEvent(getAnalytics(), 'added_watched_item', { username: context.state.user.username })
    }

    if (operation === 'remove') {
      await updateDoc(doc(db, 'Users', context.state.user.id, 'Watched', type), {
        items: arrayRemove(item.id)
      })

      // Log activity in database
      context.dispatch('utilities/logActivity', {
        created: new Date().getTime(),
        activity: 'Removed watched item',
        item: item.name || item.title,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})

      logEvent(getAnalytics(), 'removed_watched_item', { username: context.state.user.username })
    }

    context.commit('UPDATE_WATCHED_ITEMS', { type: type.toLowerCase(), val: item.id })
  },

  updateFavorites: async (context, {operation, category, type, item}) => {
    const limit = 10
    // Limit set to 10
    if (operation === 'add' && context.state.user[category].length === limit) return

    if (operation === 'add') {
      await updateDoc(doc(getFirestore(), 'Users', context.state.user.id), {
        [category]: arrayUnion(item.id)
      })

      // Log activity in database
      context.dispatch('utilities/logActivity', {
        created: new Date().getTime(),
        activity: 'Added favorite item',
        item: item.name || item.title,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})

      logEvent(getAnalytics(), 'added_favorite_item', {
        username: context.state.user.username,
        item: item.name || item.title
       })

    } else if (operation === 'remove') {
      await updateDoc(doc(getFirestore(), 'Users', context.state.user.id), {
        [category]: arrayRemove(item.id)
      })

      // Log activity in database
      context.dispatch('utilities/logActivity', {
        created: new Date().getTime(),
        activity: 'Removed favorite item',
        item: item.name || item.title,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})

      logEvent(getAnalytics(), 'removied_favorite_item', {
        username: context.state.user.username,
        item: item.name || item.title
       })
    }

    context.commit('UPDATE_FAVORITES', { category, type, val: item })
  },

  updateWatchlist: async (context, {operation, category, type, item}) => {
    const limit = 25
    // Limit set to 25
    if (operation === 'add' && context.state.user[category].length === limit) return

    if (operation === 'add') {
      await updateDoc(doc(getFirestore(), 'Users', context.state.user.id), {
        [category]: arrayUnion(item.id)
      })
      // Log activity in database
      context.dispatch('utilities/logActivity', {
        created: new Date().getTime(),
        activity: 'Added watchlist item',
        item: item.name || item.title,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})

      logEvent(getAnalytics(), 'added_watchlist_item', {
        username: context.state.user.username,
        item: item.name || item.title
       })

    } else if (operation === 'remove') {
      await updateDoc(doc(getFirestore(), 'Users', context.state.user.id), {
        [category]: arrayRemove(item.id)
      })
      // Log activity in database
      context.dispatch('utilities/logActivity', {
        created: new Date().getTime(),
        activity: 'Removed watchlist item',
        item: item.name || item.title,
        user: { name: context.state.user.username, id: context.state.user.id }
      }, {root:true})

      logEvent(getAnalytics(), 'removed_watchlist_item', {
        username: context.state.user.username,
        item: item.name || item.title
       })
    }

    context.commit('UPDATE_WATCHLIST', { category, type, val: item })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
