import api from '@/api'

const state = () => ({
  movies: {
    page: 0,
    list: [],
    calendar: [],
    query: {}
  },
  shows: {
    page: 0,
    list: [],
    query: {}
  },
  people: {
    page: 0,
    list: [],
    query: {}
  },
  query: {
    mode: '',
    item: ''
  }
})

const mutations = {
  SET_MOVIES: (state, {type, val}) => {
    if (val) {
      state.movies[type].push(val)
    }
    else {
      state.movies[type] = []
      state.movies.page = 0
      state.movies.query = {}
    }
  },

  SET_SHOWS: (state, val) => {
    if (val) {
      state.shows.list.push(val)
    }
    else {
      state.shows.list = []
      state.shows.page = 0
      state.shows.query = {}
    }
  },

  SET_PEOPLE: (state, val) => {
    if (val) state.people.list.push(val)
    else {
      state.people.list = []
      state.people.page = 0
      state.people.query = {}
    }
  },

  SET_QUERY: (state, {type, val}) => {
    if (type === 'movie') {
      state.movies.query = val
    }

    if (type === 'tv') {
      state.shows.query = val
    }

    if (type === 'person') {
      state.people.query = val
    }
  }
}

const actions = {
  reqItems: async (context, items) => {
    if (items === 'movies') {
      context.state.movies.page++

      let res = await api.tmdb.reqItems(items, context.state.movies.page)

      res.data.results.forEach(res => context.commit('SET_MOVIES', {type: 'list', val: res}))
    }

    if (items === 'shows') {
      context.state.shows.page++

      let res = await api.tmdb.reqItems(items, context.state.shows.page)

      res.data.results.forEach(res => context.commit('SET_SHOWS', res))
    }

    if (items === 'people') {
      context.state.people.page++

      let res = await api.tmdb.reqItems(items, context.state.people.page)

      res.data.results.forEach(res => context.commit('SET_PEOPLE', res))
    }
  },

  queryDatabase: async (context, { query, mode, type }) => {
    context.commit('SET_QUERY', { type, val: {item: query, mode} })

    if (type === 'movie') {
      context.state.movies.page++

      let res = await api.tmdb.queryDatabase(query, mode, type, context.state.movies.page)

      res.data.results.forEach(res => context.commit('SET_MOVIES', { type: 'list', val: res}))
    }

    if (type === 'tv') {
      context.state.shows.page++

      let res = await api.tmdb.queryDatabase(query, mode, type, context.state.shows.page)

      res.data.results.forEach(res => context.commit('SET_SHOWS', res))
    }

    if (type === 'person') {
      context.state.people.page++

      let res = await api.tmdb.queryDatabase(query, mode, type, context.state.people.page)

      res.data.results.forEach(res => context.commit('SET_PEOPLE', res))
    }
  },

  reqUpcomingItems: async (context) => {
    let films = []

    let i;

    for (i = 1; i < 5; i++) {
      let res = await api.tmdb.reqItems('movies', i, 'upcoming')

      res.data.results.forEach(film => films.push(film))
    }

    const groupByDate = films.reduce((groups, film) => {
      let date = film.release_date

      if (!groups[date]) {
        if (new Date(date) > new Date()) {
          groups[date] = [];
        }
      }

      if (new Date(date) > new Date()) {
        groups[date].push(film)
      }

      return groups
    }, {})

    const groupArrays = Object.keys(groupByDate).map((release_date) => {
      return {
        release_date,
        films: groupByDate[release_date]
      };
    });

    const endResult = groupArrays.sort((a,b)=> {
        return new Date(a.release_date) - new Date(b.release_date)
    })

    endResult.forEach(item => {
      context.commit('SET_MOVIES', {type: 'calendar', val: item})
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
