import { api, key } from '@/api'

const tmdb = {

  reqItems: (items, page, category = 'popular') => {
    if (items === 'movies') {
      return api.get(`/movie/${category}?api_key=${key}&language=en-US&page=${page}&region=US`)
    }

    if (items === 'shows') {
      return api.get(`/tv/popular?api_key=${key}&language=en-US&page=${page}&region=US`)
    }

    if (items === 'people') {
      return api.get(`/person/popular?api_key=${key}&language=en-US&page=${page}`)
    }
  },

  reqItem: (category, type, id) => {
    if (category === 'film') {
      let tags = ['credits', 'release_dates', 'recommendations', 'similar', 'videos', 'external_ids', 'watch/providers']
      return api.get(`/${type}/${id}?api_key=${key}&language=en-US&append_to_response=${tags}`)
    }

    if (category === 'person') {
      let tags = ['movie_credits', 'tv_credits', 'images', 'external_ids']
      return api.get(`/${type}/${id}?api_key=${key}&language=en-US&append_to_response=${tags}`)
    }
  },

  queryDatabase: (query, mode, type, page) => {
    if (mode === 'discover' && type === 'movie') {
      return api.get(`discover/${type}?api_key=${key}&include_adult=false&include_video=false&with_companies=${query.company}&page=${page}&primary_release_year=${query.year}&with_original_language=${query.language}&vote_average.gte=${query.rating}&with_runtime.lte=${query.runtime}&with_genres=${query.genre}`)
    }

    if (mode === 'discover' && type === 'tv') {
      return api.get(`discover/${type}?api_key=${key}&include_adult=false&include_video=false&with_companies=${query.company}&page=${page}&first_air_date_year=${query.year}&with_original_language=${query.language}&vote_average.gte=${query.rating}&with_genres=${query.genre}`)
    }

    if (mode === 'search') {
      return  api.get(`/search/${type}?api_key=${key}&language=en-US&query=${query}&page=${page}&include_adult=false`)
    }
  }
}

export default tmdb
