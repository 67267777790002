<template>
  <p class="no-token">
    There was an issue generating a new session token. Please close your browser and try again. Error: {{ message }}
  </p>
</template>

<script>
  export default {
    props: {
      message: String
    }
  }
</script>
