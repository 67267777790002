import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/style/main.scss'
import '@/services/firebase'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getToken } from 'firebase/app-check';
import { appCheck } from '@/services/firebase'
import NoToken from '@/views/404/NoToken.vue'

let app
onAuthStateChanged(getAuth(), async (user) => {
  // Initiate token
  let token;
  // Generate ReCaptcha token
  try { token = await getToken(appCheck) }
  // Handle error
  catch(error) {
    // Create app and load error template
    app = createApp(NoToken, { message: error.code }).mount('#app')

    store.dispatch('utilities/logError', {
      created: new Date().getTime(),
      error: error.message,
      user: { id: user.uid }
    })
  }

  if (token) {
    // If user exists and email is verified, get user
    if (user && user.emailVerified) {
      // Query database for user
      await store.dispatch('user/getUser', user.uid)
      // Update user with last login date
      store.dispatch('user/updateUser', { lastLogin: new Date().getTime() });
    }
    // Create app and load main template
    if (!app) app = createApp(App).use(store).use(router).mount('#app')
  }
})
