import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { getAuth } from "firebase/auth";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Display view title in tab
  let projectName = 'Flixreel'
  let title = to.meta.title
  let item = to.query.name

  if (item) {
    document.title = `${item} | ${projectName}`
  } else if (typeof title === 'undefined') {
    document.title = projectName
  } else {
    document.title = `${title} | ${projectName}`
  }

  const auth = getAuth();
  const userAuthenticated = auth.currentUser;
  const emailVerified = auth.currentUser?.emailVerified;

  if (to.meta.membersOnly && (!userAuthenticated || !emailVerified)) {

    if (to.name !== 'Profile') {
      let path = to.fullPath.split('/')[1]

      next({ path: 'login', query: { redirect: path } })
    } else {
      next('login')
    }

  } else if (to.meta.membersOnly === false && userAuthenticated && emailVerified){
    next('movies')
  } else {
    next()
  }
})

export default router
