import axios from 'axios'

/////// ENDPONTS ///////
import tmdb from './endpoints/tmdb'

/////// INITIATE API ///////
const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

/////// API KEY ///////
const key = process.env.VUE_APP_API_KEY

/////// EXPORTS ///////
export { api, key }
export default {
  tmdb
}
