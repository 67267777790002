import { addDoc, collection } from 'firebase/firestore';
import { db } from '@/services/firebase'

const state = () => ({
  searchResults: [],
  years: [
    '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010',
    '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997',
    '1996', '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986', '1985', '1984',
    '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976', '1975', '1974', '1973', '1972', '1971', '1970'
  ],
  companies: [
    { name: '20th Century Fox', id: 25 },
    { name: 'Amazon Studios', id: 20580 },
    { name: 'Columbia Pictures', id: 5 },
    { name: 'Disney', id: 2 },
    { name: 'DreamWorks', id: 7 },
    { name: 'Focus Features', id: 10146 },
    { name: 'HBO', id: 3268 },
    { name: 'HBO Documentaries', id: 14914 },
    { name: 'Illumination', id: 6704 },
    { name: 'Lions Gate', id: 35 },
    { name: 'Lucas Films', id: 1 },
    { name: 'Magnolia Pictures', id: 1030 },
    { name: 'Marvel Studios', id: 420 },
    { name: 'MGM', id: 21 },
    { name: 'Miramax', id: 14 },
    { name: 'New Line Cinema', id: 12 },
    { name: 'Paramount', id: 4 },
    { name: 'Pixar', id: 3 },
    { name: 'Sony Pictures', id: 34 },
    { name: 'STX Entertainment', id: 47729 },
    { name: 'The Weinstein Company', id: 308 },
    { name: 'Touchstone Pictures', id: 9195 },
    { name: 'Universal Pictures', id: 33 },
    { name: 'Warner Bros', id: 17 }
  ],
  genres: {
    movie: [
      { name: 'Action', id: 28 },
      { name: 'Adventure', id: 12 },
      { name: 'Animation', id: 16 },
      { name: 'Comedy', id: 35 },
      { name: 'Crime', id: 80 },
      { name: 'Documentary', id: 99 },
      { name: 'Drama', id: 18 },
      { name: 'Family', id: 10751 },
      { name: 'Fantasy', id: 14 },
      { name: 'History', id: 36 },
      { name: 'Horror', id: 27 },
      { name: 'Mystery', id: 9648 },
      { name: 'Romance', id: 10749 },
      { name: 'Sci-Fi', id: 878 },
      { name: 'TV Movie', id: 10770 },
      { name: 'Thriller', id: 53 },
      { name: 'War', id: 10752 },
      { name: 'Western', id: 37 },
    ],
    show: [
      { name: 'Action and Adventure', id: 10759 },
      { name: 'Animation', id: 16 },
      { name: 'Comedy', id: 35 },
      { name: 'Crime', id: 80 },
      { name: 'Documentary', id: 99 },
      { name: 'Drama', id: 18 },
      { name: 'Family', id: 10751 },
      { name: 'Kids', id: 10762 },
      { name: 'Mystery', id: 9648 },
      { name: 'Reality', id: 10764 },
      { name: 'Sci-Fi', id: 10765 },
      { name: 'Soap', id: 10766 },
      { name: 'War & Politics', id: 10768 },
      { name: 'Western', id: 37 },
    ]
  },
  languages: [
    { text: 'Cantanese', code: 'cn' },
    { text: 'English', code: 'en' },
    { text: 'Japanese', code: 'ja' },
    { text: 'Korean', code: 'ko' },
    { text: 'Persian', code: 'fa' },
    { text: 'Spanish', code: 'es' },
  ],
  filterVisible: false,
  modal: ''
})

const mutations = {
  TOGGLE_FILTER: (state) => {
    state.filterVisible = !state.filterVisible
  },

  TOGGLE_MODAL: (state, val) => {
    state.modal = val
  }
}

const actions = {

  logActivity: (context, details) => {
    addDoc(collection(db, 'Activity'), { ...details })
  },

  logError: (context, details) => {
    addDoc(collection(db, 'Errors'), { ...details })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
