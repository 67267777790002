import { createStore } from 'vuex'

/////// Modules ///////
import tmdb from './modules/tmdb'
import user from './modules/user'
import utilities from './modules/utilities'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tmdb,
    user,
    utilities
  }
})
