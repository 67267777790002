<template>
  <span v-if="isRefresh" class="alert message newupdate" @click="update">
    <p>A new version is available! Click here to update.</p>
  </span>
  <!-- Alert -->
  <alert v-if="alert" :alert="alert" />
  <!-- Modal -->
  <modal v-if="modal" :modal="modal"/>
  <!-- View -->
  <router-view/>
</template>

<script>
  import { defineAsyncComponent } from 'vue'
  import { mapState } from 'vuex'

  export default {
    data: () => ({
      registration: null,
      isRefresh: false,
      refreshing: false,
    }),
    components: {
      Alert: defineAsyncComponent(() => import('@/components/alert' /* webpackChunkName: 'Alert' */)),
      Modal: defineAsyncComponent(() => import('@/components/modal' /* webpackChunkName: 'Modal' */))
    },
    computed: {
      ...mapState('user', ['alert']),
      ...mapState('utilities', ['modal'])
    },
    created() {
      // Initiate new version listener
      document.addEventListener('serviceWorkerUpdateEvent', this.appUpdateUI, { once: true })
      // Refresh content
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    },
    methods: {
      appUpdateUI(e) {
        this.registration = e.detail
        this.isRefresh = true
      },
      update() {
        // this.isRefresh = false;
        if (this.registration || this.registration.waiting) {
          this.registration.waiting.postMessage({type:'SKIP_WAITING'})
        }
      }
    }
  }
</script>
